import React from "react";
import Title from "./Title";
import AboutImage from "../assets/3d-website-developer-working-on.png";
import ReadMoreComponent from "./ReadMoreComponent";

const About = () => {
  const expYear = new Date().getFullYear() - 2014;

  return (
    <div
      name="about"
      className="w-full h-full bg-gradient-to-b to-black via-black- from-gray-800 text-white"
    >
      <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <Title title="About Me" />
        </div>
        <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-8 items-center y-8 my-12">
          <div>
            <img
              src={AboutImage}
              alt="about us"
              className="rounded-2xl mx-auto md:w-full"
              loading="lazy"
              width={300}
              height={100}
            />
          </div>
          <div className="">
            <div className="mb-2 text-justify">
              <ReadMoreComponent>
                <p className="mb-4">
                  Welcome to my portfolio! My name is Vijay Chauhan, and I am a
                  full-stack web developer with a passion for creating beautiful
                  and functional websites. I have {expYear}+ years of experience
                  in the industry, and have worked with clients of all sizes,
                  from small businesses to large corporations.
                </p>
                <p className="mb-4">
                  I specialize in building responsive websites and web
                  applications using technologies such as PHP, MYSQL, HTML, CSS,
                  JavaScript, Bootstrap and various web development frameworks.
                  I also have experience in integrating with APIs and
                  implementing payment systems.
                </p>
                <p className="mb-4">
                  My approach to web development is centered around creating a
                  seamless user experience. I believe that a website should not
                  only look good, but also be easy to navigate and use. I work
                  closely with my clients to understand their needs and deliver
                  solutions that exceed their expectations.
                </p>
                <p className="mb-4">
                  In addition to my technical skills, I am a problem solver who
                  is always looking for ways to improve and optimize website
                  performance. I am also a strong communicator, able to work
                  closely with clients to understand their needs and deliver
                  solutions that exceed their expectations.
                </p>
                <p className="mb-4">
                  Thank you for visiting my portfolio. I would be happy to
                  discuss any web development project you have in mind, please
                  feel free to contact me.
                </p>
                {/* 

          <p>My name is Vijay Chauhan . I'm a Freelance Full Stack Developer based in Gujarat, India , and I'm very passionate and dedicated to my work With 7 years experience as a professional Full Stack Developer , I have acquired the skills necessary to build great and premium websites. I also contribute in open source project.</p> */}
              </ReadMoreComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
