import React from 'react'
import Title from "./Title";
import {FaLinkedin,FaGithub, FaInstagram,FaDev} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'

const Contact = () => {
  return (
    <div name="contact" className="w-full md:h-screen bg-gradient-to-b from-black to-gray-800 text-white">
    <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full">
    <div className="pb-8">
    <Title title="Contact" />
    <p className='text-xl text-blue-500'>Feel Free,</p>
            <p className='text-sm text-blue-300'>Say hi;</p>
    </div>
    <div className="flex flex-col items-center justify-between p-8 md:flex-row md:pt-2">
      <div>
        <ul className="grid grid-rows-1 gap-4 text-gray-100 sm:grid-cols-2">
          <li><a className='flex gap-1' target="_blank" href="https://www.linkedin.com/in/mr-vijaychauhan"><FaLinkedin size={20}/> /mr.vijaychauhan</a></li>
          <li><a className='flex gap-1' target="_blank" href="https://github.com/mr-vijaychauhan"><FaGithub size={20}/> /mr.vjchauhan</a></li>
          <li><a className='flex gap-1' target="_blank" href="https://instagram.com/mr.vjchauhan"><FaInstagram size={20}/> /mr-vijaychauhan</a></li>
          <li><a className='flex gap-1' target="_blank" href="https://dev.to/mrvijaychauhan"><FaDev size={20}/> /mrvijaychauhan</a></li>
        </ul>
      </div>
      <div className="flex items-center justify-center">
        <div className="flex flex-col justify-center items-center py-8 lg:py-20">
          <h1 className="text-2xl text-blue-500">Start Project?</h1>
          <span className='flex items-center gap-1'>
            <a className='flex items-center gap-1' href='mailto:contact@vjchauhan.com'>
            <HiOutlineMail/> contact@vjchauhan.com
            </a>
            </span>
        </div>
      </div>
    </div>
    </div>
  </div>
  )
}

export default Contact