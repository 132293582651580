import React from 'react'
import {FaLinkedin,FaGithub, FaInstagram} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonFill} from 'react-icons/bs'

const SocialLinks = () => {
    
    const links = [
        {
           id:1,
           child: (
                <>
                    Linkedin <FaLinkedin size={30}/> 
                </>   
           ),
           href: "https://www.linkedin.com/in/mr-vijaychauhan/",
           style : 'rounded-tr-md'
        }, 
        {
            id:2,
            child: (
                 <>
                     Github <FaGithub size={30}/> 
                 </>   
            ),
            href: "https://github.com/mr-vijaychauhan",
         }, 
         {
            id:3,
            child: (
                 <>
                     Instagram <FaInstagram size={30}/> 
                 </>   
            ),
            href: "https://instagram.com/mr.vjchauhan",
         }, 
         {
            id:4,
            child: (
                 <>
                     Mail <HiOutlineMail size={30}/> 
                 </>   
            ),
            href: "mailto:contact@vjchauhan.com",
         },
         {
            id:5,
            child: (
                 <>
                     Resume <BsFillPersonFill size={30}/> 
                 </>   
            ),
            href: "/resume.pdf",
            style:'rounded-br-md',
            download:true,
         },  
    ]

  return (
    <div className='hidden lg:flex flex-col top-[35%] left-0 fixed'>
        <ul>
            {
             links.map(({id,child,href,style,download}) =>(
                 <li key={id} className={`flex justify-between items-center w-40 h-14 px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-500 border border-blue-500 ${style}`}>
                    
                 <a href={href} className='flex justify-between items-center w-full text-white' download={download} target="_blank" rel="noreferrer" >
                     {child}
                 </a>
             </li>
            ))}
           
        </ul>
    </div>
  )
}

export default SocialLinks