import About from "./components/About";
import Contact from "./components/Contact";
import Experience from "./components/Experience";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Portfolio from "./components/Portfolio";
import SocialLinks from "./components/SocialLinks";


function App() {
  const expYear= new Date().getFullYear() - 2014;

  return (
    <div>
      <Navbar />  
      <Home/> 
      <About/>
      <Portfolio/>
      <Experience />
      <Contact/>
      <Footer/>
      <SocialLinks /> 
    </div>
  );
}

export default App;
