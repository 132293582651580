import React from "react";

export default function Modal({title,url,desc,child,src,fulldesc}) {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <button
        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        More info
      </button>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm bg-black/30"
          >
            <div className="relative w-auto my-6 mx-6 sm:mx-auto max-w-xl ">
              {/*content*/}
              <div className="border border-solid border-blue-500 rounded-lg shadow-lg relative flex flex-col w-full bg-gradient-to-b from-black/90 via-black to-gray-800 outline-none focus:outline-none m-auto">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blue-200 rounded-t">
                  <h3 className="text-2xl font-semibold text-gray-200">
                    {title}
                  </h3>
                  <button
                    className="p-1 ml-auto border-0 text-white float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className=" text-white  h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                    <div className="flex justify-center overflow-x-auto scrollbar-hide">
                        
                <img src={src} className="object-cover rounded-md duration-200 hover:scale-105 bg-blue-300 " alt="" loading="lazy" width="400" height="400" />
                    </div>
                  <p className="w-full my-4 text-slate-500 text-lg leading-relaxed text-justify">
                    {fulldesc}
                  </p>
                  {child}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blue-200 rounded-b">
                  <button
                    className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <a href={url}
                    className="text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
                    type="button"
                    target='_blank' rel="noreferrer"
                  >
                    Visit Website
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

          
        </>
      ) : null}
    </>
  );
}