import React from 'react'
import Title from "./Title";
import Php from '../assets/experience/php.png'
import Mysql from '../assets/experience/mysql.png'
import Bootstrap from '../assets/experience/bootstrap.png'
import Html from '../assets/experience/html.png'
import Css from '../assets/experience/css.png'
import Github from '../assets/experience/github.png'
import Javascript from '../assets/experience/javascript.png'
import Node from '../assets/experience/node.png'
import NextJs from '../assets/experience/nextjs.png'
import ReactJs from '../assets/experience/react.png'
import Tailwind from '../assets/experience/tailwind.png'
import Photoshop from '../assets/experience/photoshop.png'


const Experience = () => {
 
  const tech = [
    { 
      id:1,
      src:Php,
      title: 'PHP',
      style : 'shadow-[#474A8A]',
      percentage: '100%',
    },
    { 
      id:2,
      src:Mysql,
      title: 'MySql',
      style : 'shadow-[#00758f]',
      percentage: '90%',
    },
    { 
      id:3,
      src: Bootstrap,
      title: 'Bootstrap',
      style : 'shadow-[#563d7c]',
      percentage: '90%',
    },
    { 
      id:4,
      src:Html,
      title: 'HTML',
      style : 'shadow-orange-500',
      percentage: '100%',
    },
    { 
      id:5,
      src:Css,
      title: 'CSS',
      style : 'shadow-blue-500',
      percentage: '90%',
    },
    { 
      id:6,
      src: Github,
      title: 'GitHub',
      style : 'shadow-gray-400',
      percentage: '90%',
    },
    { 
      id:7,
      src:Javascript,
      title: 'Javascript',
      style : 'shadow-yellow-500',
      percentage: '70%',
    },
    { 
      id:8,
      src:Node,
      title: 'Nodejs',
      style : 'shadow-green-500',
      percentage: '40%',
    },
    { 
      id:9,
      src:NextJs,
      title: 'NextJs',
      style : 'shadow-white',
      percentage: '50%',
    },
    { 
      id:10,
      src:ReactJs,
      title: 'ReactJs',
      style : 'shadow-blue-600',
      percentage: '75%',
    },
    { 
      id:11,
      src:Tailwind,
      title: 'Tailwind',
      style : 'shadow-sky-400',
      percentage: '80%',
    },
    { 
      id:12,
      src:Photoshop,
      title: 'Photoshop',
      style : 'shadow-[#0d3058]',
      percentage: '80%',
    },

    
  ]
 
  return (
    <div name="experience" className='bg-gradient-to-b from-gray-800 to-black w-full md:h-screen'>
      <div className='max-w-screen-xl mx-auto p-4 flex flex-col justify-center w-full h-full text-white'>
        <div className='pb-8'>
          <Title title="Experience" />
          <p className="py-8">These are the Technologies I've worked with</p>
        </div>

        <div className='w-full grid grid-cols-2 sm:grid-cols-7 gap-8 text-center py-8 px-12 sm:px-0'>

        {
          tech.map(({id,src,title,style,percentage}) =>(
            <div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
            <img src={src} alt={title} className='w-20 mx-auto' width={80} height={80} loading="lazy"/>
              {/* <span className='mx-0 text-sm text-gray-500'>{percentage}</span> */}
            <p>{title}</p>
            </div>
          ))
        }
        </div>
      </div>
    </div>
  )
}

export default Experience


