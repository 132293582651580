import React from "react";
import { Link } from "react-scroll";

const Footer = () => {
   const curYear =new Date().getFullYear() % 100;
  return (
    <div className="bg-gradient-to-b from-black via-black- to-gray-800 text-white w-full h-full">
      <div className="flex flex-col justify-evenly p-8 md:flex-row items-center">
        <div className="">
          <ul className="flex gap-4 text-gray-500">
            <li>
              <Link to="home" smooth duration={500}>
                Home
              </Link>
            </li>
            <li>
              <Link to="about" smooth duration={500}>
                About
              </Link>
            </li>
            <li>
              <Link to="portfolio" smooth duration={500}>
                Portfolio
              </Link>
            </li>
            <li>
              <Link to="experience" smooth duration={500}>
                Experience
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex justify-center items-center text-center py-4">
          @ 2022-{curYear} . mr.vijaychauhan. All Right Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
