import React from "react";
import HeroImage from "../assets/kindpng_1247334.png";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonFill } from "react-icons/bs";
import { Link } from "react-scroll";

const Home = () => {

  const expYear= new Date().getFullYear() - 2014;


  const links = [
    {
      id: 1,
      child: (
        <>
          <FaLinkedin size={16} />
        </>
      ),
      href: "https://www.linkedin.com/in/mr-vijaychauhan/",
      style: "rounded-tr-md",
    },
    {
      id: 2,
      child: (
        <>
          <FaGithub size={16} />
        </>
      ),
      href: "https://github.com/mr-vijaychauhan",
    },
    {
      id: 3,
      child: (
        <>
          <HiOutlineMail size={16} />
        </>
      ),
      href: "mailto:mr.vijaychauhan08@gmail.com",
    },
    {
      id: 4,
      child: (
        <>
          <BsFillPersonFill size={16} />
        </>
      ),
      href: "/resume.pdf",
      style: "rounded-br-md",
      download: true,
    },
  ];

  return (
    <div
      name="home"
      className="h-screen w-full bg-gradient-to-b from-black via-black- to-gray-800 "
    >
      <div className="max-w-screen-xl mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row ">
        <div className="flex flex-col justify-center h-full">
          <div className="flex flex-row justify-between">
            <h2 className="text-4xl sm:text-7xl font-bold text-white">
              I'm a Full Stack Developer
            </h2>
          </div>
          <p className="text-gray-500 py-1 max-w-md">
            Hi there ✋, I am{" "}
            <span className="text-2xl font-signature text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500">
              Vijay Chauhan
            </span>{" "}
            from India. I have been a Full Stack Web Developer for the last {expYear}+
            years. I have used technology like PHP, Mysql, Reactjs, Github,
            Boostrap, and Many more...
          </p>
          <div className="pb-1">
            <div className="flex items-center">
              <Link
                to="portfolio"
                smooth
                duration={500}
                className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
              >
                Portfolio
                <span className="group-hover:rotate-90 duration-300">
                  <MdKeyboardArrowRight size={25} className="ml-1" />
                </span>
              </Link>
              <Link
                to="contact"
                smooth
                duration={500}
                className="bg-transparent hover:bg-white text-blue-500 font-semibold hover:text-blue-500 px-6 py-3 my-2 border border-blue-500 hover:border-transparent rounded ml-2"
              >
                Hire Me
              </Link>
            </div>
          </div>
          <div className="lg:hidden flex flex-col justify-start items-start gap-3">
            <h4 className="text-xl text-white font-bold">Find me on :</h4>
            <ul className="flex justify-start items-center flex-wrap gap-4">
              {links.map(({ id, child, href, style, download }) => (
                <li key={id} title="Twitter">
                  <a
                    href={href}
                    className="text-blue-500 text-md ease-in-out duration-150 hover:text-white"
                    download={download}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {child}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="px-4 py-8 hidden lg:block">
          <img
            src={HeroImage}
            alt="profile pics"
            className="rounded-2xl mx-auto md:w-full"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
