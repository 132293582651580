import React from "react";
import { useState } from "react";
function short(arr, maxLen) {
  let l = 0;
  for (let i = 0; i < arr.length; i++) {
    if (l + arr[i].props.children.length > maxLen) {
      let res = arr.slice(0, i);
      let partial = arr[i].props.children.substring(0, maxLen - l);
      res.push(<p className="mb-4">{partial}</p>);
      return res;
    }
    l = l + arr[i].props.children.length;
  }
  return arr;
}
function ReadMoreComponent(props) {
  const [shortMode, setShortMode] = useState(true);
  return (
    <React.Fragment>
      {shortMode ? (
        <React.Fragment>{short(props.children, 800)}</React.Fragment>
      ) : (
        props.children
      )}
      <button
        className="text-blue-500 font-semibold"
        onClick={() => setShortMode(!shortMode)}
      >
        {shortMode ? "Show More" : "Show Less"}
      </button>
    </React.Fragment>
  );
}

export default ReadMoreComponent;
