import React from 'react'
import Title from "./Title";
import Modal from "./Modal";
import {FiExternalLink} from 'react-icons/fi'
import BusinessBooky from '../assets/portfolio/BusinessBooky.png'
import Covid19 from '../assets/portfolio/Covid19.png'
// import BookSuggestion from '../assets/portfolio/Book-Suggestion.png'
import Gardening from '../assets/portfolio/Homepage-Gardening.png'


const Portfolio = () => {

  /*   const redirectTo = ({url}) =>{
        console.log(url);
    } */
    
    const portfolio = [
        {
            id:1,
            src: Gardening,
            title: 'Gardening Admin Panel Using Filament',
            desc : 'In this project, I designed and developed a comprehensive admin panel for a gardening platform.',
            url : 'https://gardening.vjchauhan.com/',
            child: (
                <>
                <div className='my-2 overflow-x-auto scrollbar-hide'>
                    <span className='py-1 px-2 text-xs  text-white font-semibold bg-blue-500 rounded-full'>Laravel</span>  
                    <span className='py-1 px-2 mx-1 text-xs  text-white font-semibold bg-blue-500 rounded-full'>Bootstrap</span> 
                    <span className='py-1 px-2 text-xs mx-2 text-white font-semibold bg-blue-500 rounded-full'>Livewire</span> 
                    <span className='py-1 px-2 text-xs  text-white font-semibold bg-blue-500 rounded-full'>PHP</span> 
                </div>
                </>   
           ),
           fulldesc:'In this project, I designed and developed a comprehensive admin panel for a gardening platform. It involved intricate backend management capabilities and user-friendly interfaces, leveraging the full potential of the Filament framework for optimal performance and scalability.' ,
        },
        {
            id:2,
            src: BusinessBooky,
            title: 'Business Booky',
            desc : 'This project entailed developing a comprehensive business listing website.',
            url : 'https://www.businessbooky.com/',
            child: (
                <>
                <div className='my-2 overflow-x-auto scrollbar-hide'>
                    <span className='py-1 px-2 text-xs  text-white font-semibold bg-blue-500 rounded-full'>PHP</span> 
                    <span className='py-1 px-2 mx-2 text-xs  text-white font-semibold bg-blue-500 rounded-full'>Mysql</span> 
                    <span className='py-1 px-2 text-xs  text-white font-semibold bg-blue-500 rounded-full'>Bootstrap</span> 
                </div>
                </>   
           ),
           fulldesc:'This project entailed developing a comprehensive business listing website. It showcased my capability to handle complex data structures and create an intuitive user interface, ensuring a seamless user experience and efficient data management.' ,
        },
        {
            id:3,
            src: Covid19,
            title: 'Covid19 Tracker',
            desc : 'Coronavirus disease Case Tracker in the World',
            url : 'https://mr-vijaychauhan.github.io/covid19/',
            child: (
                <>
                <div className='my-2 overflow-x-auto scrollbar-hide'>
                    <span className='py-1 px-2 text-xs  text-white font-semibold bg-blue-500 rounded-full'>Javascript</span> 
                    <span className='py-1 px-2 mx-2 text-xs  text-white font-semibold bg-blue-500 rounded-full'>Mapbox</span> 
                    <span className='py-1 px-2 text-xs  text-white font-semibold bg-blue-500 rounded-full'>Bootstrap</span> 
                </div>
                </>   
           ),
           fulldesc:'This Covid19 repo based on Covid-19 Coronavirus disease Case Tracker. You can check detail of coronavirus cases, recovered and death by country and states using Map and data.' ,
        
        },
        /* {
            id:3,
            src: BookSuggestion,
            title: 'Books Suggestion System.',
            desc : 'Books Suggestion System with Machine Learning.',
            url : 'https://book-suggestion-system.herokuapp.com/',
            child: (
                <>
                <div className='m-2 overflow-x-auto scrollbar-hide px-2'>
                    <span className='py-1 px-2 text-xs  text-white font-semibold bg-blue-500 rounded-full'>Flask</span>  
                    <span className='py-1 px-2 mx-1 text-xs  text-white font-semibold bg-blue-500 rounded-full'>Bootstrap</span> 
                    <span className='py-1 px-2 text-xs mx-2 text-white font-semibold bg-blue-500 rounded-full'>API</span> 
                    <span className='py-1 px-2 text-xs  text-white font-semibold bg-blue-500 rounded-full'>Pyhton</span> 
                </div>
                </>   
           ),
           fulldesc:'Book Suugestion is a Recommendation of Top Books to help to choose your Favourite Book using this App.' ,
        }, */
       
        
    ]

  return (
<div name="portfolio" className='bg-gradient-to-b from-black to-gray-800 w-full md:h-screen text-white'>
    <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full" >
        <div className='pb-8'>
          <Title title="Portfolio" />  
            <p className='py-8'>Some Things I’ve Built</p>    
        </div>
        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0'>
            {
                 portfolio.map(({id,src,title,desc,url,child,fulldesc}) =>(
                    <div key={id} className='shadow-md shadow-gray-600 rounded-lg flex flex-col justify-between align-bottom'>
                    <img src={src} className='bg-gray-400 rounded-md w-[600px] h-[250px] object-fill duration-200 hover:scale-105' loading='lazy' alt={title} />    
                    <div className='flex flex-col p-2'>
                        <h1 className='mt-2 flex-1'>{title}</h1>
                        {child}
                        <p className='text-gray-500 flex-1'>
                            {desc}
                        </p>
                        
                    </div>
                    <div className='flex grow items-center px-3 py-3 mx-2 text-sm'>
                        <a href={url} className='flex items-center m-4  duration-200 hover:scale-105 text-sm text-blue-500' target='_blank' rel="noreferrer">
                            <FiExternalLink className="mx-1"/>Visit Website
                        </a>
                       <Modal title={title} url={url} desc={desc} child={child} src={src} fulldesc={fulldesc} />
                    </div>
                </div>   
                    ))
            }    
        </div>    
    </div>   
</div>
  )
}

export default Portfolio