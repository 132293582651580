import React from 'react'

const Title = ({title}) => {
  return (
    <>
        <p className='text-4xl font-bold p-2'><span className='text-blue-500'>#</span> {title}</p>
    </>
  )
}

export default Title