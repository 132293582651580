import React, { useState } from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import {Link} from 'react-scroll'

const Navbar = () => {

    const [nav, setNav] = useState(false)

    const links=[
        {
            id:1,
            link:'home',
            prefixno:'1',
        },
        {
            id:2,
            link:'about',
            prefixno:'2',
        },
        {
            id:3,
            link:'portfolio',
            prefixno:'3',
        },
        {
            id:4,
            link:'experience',
            prefixno:'4',
        },
        {
            id:5,
            link:'contact',
            prefixno:'5',
        },
    ];


    
  return (
    <div className='flex justify-between items-center w-full h-20 px-4 text-white fixed bg-black'>
        <div>
            <h1 className='text-5xl font-signature ml-2'>
               <Link to='home' smooth duration={500} > mr.vjchauhan</Link>
            </h1>
        </div>
            <ul className="hidden md:flex">
                {links.map(({id,link,prefixno}) => (
                     <li key={id} className=' flex px-4 cursor-pointer font-medium capitalize text-gray-500 hover:scale-105 duration-200'><span className='text-blue-500'>#  </span>
                     <Link to={link} smooth duration={500}>{link}</Link>
                     </li>
                ))}
               
            </ul>
            <div onClick={()=> setNav(!nav)} className='cursor-pointer pr-4 z-10 text-gray-500 md:hidden focus:bg-none'>
                {nav ? <FaTimes size={30} /> : <FaBars size={30}/>}
                
            </div>

                {nav && (
                    <ul className='flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-gray-500'>
                    {links.map(({id,link,prefixno}) => (
                         <li key={id} className='px-4 cursor-pointer capitalize py-6 text-4xl hover:scale-105 duration-200'>
                            <span className='text-blue-500'># </span>
                            <Link onClick={()=> setNav(!nav)} to={link} smooth duration={500}>{link}</Link>
                        </li>
                    ))}
                </ul>
                )}
            
    </div>
  )
}

export default Navbar